<template lang="pug">
head-meta(title-page="Страница не найдена")
the-error
contacts-line
</template>

<script>
import TheError from '../components/error/TheError'
import HeadMeta from '../components/_layout/HeadMeta'
import ContactsLine from '../components/_layout/TheContactsLine'

export default {
  name: 'ErrorPage',
  components: { ContactsLine, HeadMeta, TheError }
}
</script>
